'use_strict';

import $ from 'jquery';
import ymaps from 'ymaps';
import lozad from 'lozad';
import Inputmask from 'inputmask';
import HandyCollapse from 'handy-collapse';
import { Fancybox } from '@fancyapps/ui';
let Swiper = require('swiper/swiper-bundle.min');
let ionRangeSlider = require('ion-rangeslider/js/ion.rangeSlider.min');

document.addEventListener('DOMContentLoaded', () => {
  window.$ = $;
  // Анимированное меню
  $('.menu-trigger').on('click', function () {
    $(this).find('button').toggleClass('is-active');
    $(this).closest('#header').find('.menu').toggleClass('active');

    if ($(this).find('button').hasClass('is-active')) {
      // Блокировка скролла
      let scrollPosition = [
        self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
        self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop,
      ];
      let html = jQuery('html'); // it would make more sense to apply this to body, but IE7 won't have that
      html.data('scroll-position', scrollPosition);
      html.data('previous-overflow', html.css('overflow'));
      html.css('overflow', 'hidden');
      window.scrollTo(scrollPosition[0], scrollPosition[1]);
    } else {
      // Разблокировка скролла
      let html = jQuery('html');
      let scrollPosition = html.data('scroll-position');
      html.css('overflow', html.data('previous-overflow'));
      window.scrollTo(scrollPosition[0], scrollPosition[1]);
    }
  });
  $('#header .menu')
    .not('#header .menu nav')
    .on('click', function () {
      $(this).removeClass('active');
      $('.menu-trigger button').removeClass('is-active');

      // Разблокировка скролла
      let html = jQuery('html');
      let scrollPosition = html.data('scroll-position');
      html.css('overflow', html.data('previous-overflow'));
    });

  // Отложенная загрузка
  const observer = lozad();
  observer.observe();

  // Открытие попапа
  $('.popup')
    .add('.block-popup')
    .on('click', function (e) {
      e.preventDefault();

      let yagoal = $(this).attr('data-yagoal'),
        catalogItem = $(this).attr('data-item'),
        variantName = $(this).attr('data-variant');

      let popup;
      if ($(this).hasClass('block-popup')) {
        popup = $(this).attr('data-href');
      } else {
        popup = $(this).attr('href');
      }

      $(popup).addClass('open');
      if (variantName && variantName.length > 0) {
        $(popup)
          .find('.hidden input.email.title')
          .val('Хочу потолок ' + variantName);
        $(popup).find('span.variant').text(variantName);
      }

      if ($(this).hasClass('catalog-modal')) {
        $(popup).find('.modal__block-slider .slider').removeClass('active');
        $(popup)
          .find('.modal__block-slider .slider-' + catalogItem)
          .addClass('active');
        $(popup).find('.modal__block-info .content').removeClass('active');
        $(popup)
          .find('.modal__block-info .content-' + catalogItem)
          .addClass('active');
      }

      // Блокировка скролла
      let scrollPosition = [
        self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
        self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop,
      ];
      let html = jQuery('html'); // it would make more sense to apply this to body, but IE7 won't have that
      html.data('scroll-position', scrollPosition);
      html.data('previous-overflow', html.css('overflow'));
      html.css('overflow', 'hidden');
      window.scrollTo(scrollPosition[0], scrollPosition[1]);
    });

  // Закрытие попапа
  $('.modal .close-modal').on('click', function () {
    $(this).parent().removeClass('open');

    // Разблокировка скролла
    let html = jQuery('html');
    let scrollPosition = html.data('scroll-position');
    html.css('overflow', html.data('previous-overflow'));
  });
  $('.modal .close-icon').on('click', function () {
    let dest = $(this).attr('data-popup');
    $('#' + dest).removeClass('open');

    // Разблокировка скролла
    let html = jQuery('html');
    let scrollPosition = [0, 0];
    html.css('overflow', html.data('previous-overflow'));
  });

  $('form .input input[name="name"]').on('blur', function (e) {
    let name = $(this).val(),
      regexp = /^[a-z\s]+$/i;

    if (regexp.test(name)) {
      e.preventDefault();

      // Swal.fire({
      //   position: 'center',
      //   type: 'error',
      //   title: 'Speak Russian, please!',
      //   showConfirmButton: false,
      //   timer: 10000,
      // });

      $(this).val('');
    }
  });

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const loadReviews = () => {
    let nameSpace = 'reviews-el';
    new HandyCollapse({
      isAnimation: true,
      closeOthers: false,
      cssEasing: 'linear',
      nameSpace: nameSpace,
      activeClass: 'js-is-opened',
      animationSpeed: 300, // less 300 gives weird behaviour
      onSlideEnd: (isOpen, contentID) => {
        let currentContentEl = document.querySelector(`[data-${nameSpace}-content='${contentID}']`);
        if (isOpen) {
          currentContentEl.closest('.review-card__body').classList.add('js-is-opened');
        } else {
          currentContentEl.closest('.review-card__body').classList.remove('js-is-opened');
        }
      },
    });
  };

  if (document.querySelector('.review-card')) loadReviews();

  if (document.getElementById('show-more-reviews-btn'))
    document.getElementById('show-more-reviews-btn').addEventListener('click', (e) => {
      const reviewsList = document.querySelector('.reviews__list');
      const showMoreBtn = e.currentTarget;

      showMoreBtn.disabled = true;
      reviewsList.classList.add('js-active-ajax-content-placeholder');

      const formData = new FormData();
      formData.append(
        'visible-reviews-amount',
        document.querySelectorAll('.reviews__list .review-card').length
      );

      const xhr = new XMLHttpRequest();
      xhr.open('POST', settings.ajax.url + '?action=show_more_reviews');
      xhr.send(formData);
      xhr.onload = function () {
        const parsedResponse = JSON.parse(xhr.response);

        if (xhr.status != 200) {
          console.log(`Error ${xhr.status}: ${xhr.statusText}. Reason: ${parsedResponse.data}`);
        } else {
          reviewsList.insertAdjacentHTML('beforeend', parsedResponse.data['reviews-content']);

          loadReviews();

          if (parsedResponse.data['show-more-btn-is-hidden']) showMoreBtn.remove();
        }

        showMoreBtn.disabled = false;
        reviewsList.classList.remove('js-active-ajax-content-placeholder');
      };

      xhr.onerror = function () {
        console.log(`Error ${xhr.status}: ${xhr.statusText}`);
      };
    });

  // Отправка письма
  document.querySelectorAll('form.ajax').forEach((form) => {
    form.addEventListener('submit', (evt) => {
      evt.preventDefault();

      let yaGoal = form.querySelector('input.yagoal').value,
        phone = form.querySelector('input[name="phone"]').value;

      let html = jQuery('html');

      let data = new FormData(form);
      leadgets('lead', data, (r) => {
        if (r.status == 1) {
          document.querySelectorAll('.modal').forEach((el) => el.classList.remove('open'));
          form.querySelector('input[type="submit"]').removeAttribute('disabled', '');
          if (form.querySelector('input[type="checkbox"].option'))
            form
              .querySelectorAll('input[type="checkbox"].option')
              .forEach((el) => (el.checked = false));
          if (form.querySelector('input[name="file_attach"]'))
            form.querySelector('input[name="file_attach"]').value = '';
          if (form.querySelector('input[name="name"]'))
            form.querySelector('input[name="name"]').value = '';
          if (form.querySelector('input[name="phone"]'))
            form.querySelector('input[name="phone"]').value = '';
          if (form.querySelector('input[name="email"]'))
            form.querySelector('input[name="email"]').value = '';
          if (form.querySelector('input[name="client_city"]'))
            form.querySelector('input[name="email"]').value = '';
          if (form.querySelector('textarea')) form.querySelector('textarea').value = '';
          if (form.querySelector('input[name="vacancy"]'))
            form.querySelector('input[name="vacancy"]').value = '';
          if (form.querySelector('input[name="town"]'))
            form.querySelector('input[name="town"]').value = '';
          if (form.querySelector('.file-added')) form.querySelector('.file-added').innerHTML = '';

          if (form.id == 'review-form') {
            setTimeout(() => {
              document.querySelector('#thanks-for-review').classList.add('open');
            }, 150);
            setTimeout(() => {
              document.querySelector('#thanks-for-review').classList.remove('open');
              html.css('overflow', 'visible');
            }, 3500);
          } else {
            setTimeout(() => {
              document.querySelector('#thanks').classList.add('open');
            }, 150);
            setTimeout(() => {
              document.querySelector('#thanks').classList.remove('open');
              html.css('overflow', 'visible');
            }, 3500);
          }

          if (typeof ym == 'function') ym('56851582', 'reachGoal', 'ym_form_lead');
          if (typeof ga == 'function') ga('send', 'event', 'form', 'form_lead');
          if (yaGoal.length > 0) console.log(yaGoal);
        } else {
        }
      });
    });
  });

  // Слайдеры
  if (document.querySelector('.examples__slider__inner')) {
    let examplesSlider = new Swiper('.examples__slider__inner', {
      grabCursor: true,
      lazy: {
        loadPrevNext: false,
        loadOnTransitionStart: true,
      },
      loop: true,
      spaceBetween: 60,
      speed: 500,
      slidesPerView: 1,
      navigation: {
        nextEl: '.examples__slider__control:nth-child(2)',
        prevEl: '.examples__slider__control:nth-child(1)',
      },
      preloadImages: false,
    });
  }

  if (document.querySelector('.gallery-our-work__slider__inner')) {
    let galleryOurWorkSlider = new Swiper('.gallery-our-work__slider__inner', {
      grabCursor: true,
      lazy: {
        loadPrevNext: false,
        loadOnTransitionStart: true,
      },
      loop: true,
      spaceBetween: 16,
      speed: 500,
      breakpoints: {
        600: {
          slidesPerView: 1,
        },
        800: {
          slidesPerView: 2,
        },
        1000: {
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: '.gallery-our-work__slider__control:nth-child(2)',
        prevEl: '.gallery-our-work__slider__control:nth-child(1)',
      },
      preloadImages: false,
    });
  }

  if (document.querySelector('.recommend__slider__inner.left-slider')) {
    let recommendSliderLeft = new Swiper('.recommend__slider__inner.left-slider', {
      grabCursor: true,
      lazy: {
        loadPrevNext: false,
        loadOnTransitionStart: true,
      },
      loop: true,
      spaceBetween: 0,
      speed: 500,
      slidesPerView: 1,
      navigation: {
        nextEl: '.recommend__slider__control.left-slider:nth-child(2)',
        prevEl: '.recommend__slider__control.left-slider:nth-child(1)',
      },
      preloadImages: false,
    });
    let recommendSliderRight = new Swiper('.recommend__slider__inner.right-slider', {
      grabCursor: true,
      lazy: {
        loadPrevNext: false,
        loadOnTransitionStart: true,
      },
      loop: true,
      spaceBetween: 0,
      speed: 500,
      slidesPerView: 1,
      navigation: {
        nextEl: '.recommend__slider__control.right-slider:nth-child(2)',
        prevEl: '.recommend__slider__control.right-slider:nth-child(1)',
      },
      preloadImages: false,
    });
  }

  if (document.querySelector('.reviews-slider .swiper-container')) {
    let reviewsSlider = new Swiper('.reviews-slider .swiper-container', {
      grabCursor: true,
      spaceBetween: 20,
      speed: 500,
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        800: {
          slidesPerView: 2,
        },
      },
      navigation: {
        nextEl: '.reviews-slider .swiper-button-next',
        prevEl: '.reviews-slider .swiper-button-prev',
      },
      pagination: {
        el: '.reviews-slider .swiper-pagination',
        clickable: true,
      },
    });
  }

  // Каталог
  if (document.querySelector('.swiper-container.catalog-slider__container')) {
    let catalogslider = new Swiper('.swiper-container.catalog-slider__container', {
      grabCursor: true,
      preloadImages: false,
      lazy: {
        loadPrevNext: false,
        loadOnTransitionStart: true,
      },
      loop: true,
      spaceBetween: 10,
      speed: 500,
      autoplay: {
        delay: 300000,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        600: {
          slidesPerView: 2,
        },
        1000: {
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next.catalog-slider__swiper-btn',
        prevEl: '.swiper-button-prev.catalog-slider__swiper-btn',
      },
      preloadImages: false,
    });
  }

  // Видео примеры работ
  if (document.querySelector('.swiper-container.video-examples-of-work__swiper-container')) {
    let videoExamplesOfWorkSlider = new Swiper(
      '.swiper-container.video-examples-of-work__swiper-container',
      {
        grabCursor: true,
        loop: true,
        lazy: true,
        preloadImages: false,
        spaceBetween: 18,
        speed: 500,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          550: {
            slidesPerView: 2,
          },
          800: {
            slidesPerView: 3,
          },
          1050: {
            slidesPerView: 4,
          },
        },
        pagination: {
          el: '.swiper-pagination.video-examples-of-work__pagination',
          clickable: true,
        },
        navigation: {
          prevEl: '.swiper-button-prev.video-examples-of-work__slider-btn',
          nextEl: '.swiper-button-next.video-examples-of-work__slider-btn',
        },
      }
    );
  }

  // Примеры выполненных работ
  if (document.querySelector('.swiper-container.examples-of-completed-work__swiper-container')) {
    let examplesOfCompletedWorkSlider = new Swiper(
      '.swiper-container.examples-of-completed-work__swiper-container',
      {
        grabCursor: true,
        preloadImages: false,
        lazy: true,
        loop: true,
        spaceBetween: 18,
        speed: 500,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          550: {
            slidesPerView: 2,
          },
          800: {
            slidesPerView: 3,
          },
          1050: {
            slidesPerView: 4,
          },
        },

        navigation: {
          prevEl: '.swiper-button-prev.examples-of-completed-work__slider-btn',
          nextEl: '.swiper-button-next.examples-of-completed-work__slider-btn',
        },
      }
    );
  }

  // Готовые работы
  if (document.querySelector('.portfolio-slider')) {
    $('.portfolio-slider').each(function (index, element) {
      var portfolioSlider = new Swiper('.swiper-container.portfolio-slider-' + index, {
        grabCursor: true,
        loop: true,
        spaceBetween: 50,
        speed: 800,
        autoplay: {
          delay: 30000,
        },
        slidesPerView: 1,
        navigation: {
          nextEl: '.swiper-button-next.portfolio-slider-' + index,
          prevEl: '.swiper-button-prev.portfolio-slider-' + index,
        },

        pagination: {
          el: '.swiper-pagination.portfolio-slider-' + index,
          clickable: true,
        },
        preloadImages: false,
        lazy: true,
        lazy: {
          loadPrevNext: false,
        },
      });
    });
  }

  // Примеры работ
  if (document.querySelector('.examples-slider')) {
    $('.examples-slider').each(function (index, element) {
      let slider = new Swiper('.swiper-container.examples-slider-' + index, {
        grabCursor: true,
        loop: true,
        spaceBetween: 16,
        speed: 500,

        autoplay: {
          delay: 30000,
        },
        breakpoints: {
          415: {
            slidesPerView: 1,
          },
          550: {
            slidesPerView: 2,
          },
          820: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next.examples-slider-' + index,
          prevEl: '.swiper-button-prev.examples-slider-' + index,
        },
        pagination: {
          el: '.swiper-pagination.examples-slider-' + index,
          clickable: true,
        },
        preloadImages: false,
        lazy: true,
        lazy: {
          loadPrevNext: false,
        },
      });
    });
  }

  // Почему Супер потолок
  if (document.querySelector('.swiper-container.why-list')) {
    let whySlider = new Swiper('.swiper-container.why-list', {
      grabCursor: true,
      loop: true,
      spaceBetween: 15,
      speed: 500,
      autoplay: {
        delay: 30000,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next.why-list',
        prevEl: '.swiper-button-prev.why-list',
      },
    });
  }

  // Как заказать
  if (document.querySelector('.swiper-container.how-to-slider')) {
    let howToSlider = new Swiper('.swiper-container.how-to-slider', {
      grabCursor: true,
      loop: true,
      spaceBetween: 30,
      speed: 500,
      autoplay: {
        delay: 30000,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        460: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next.how-to-slider',
        prevEl: '.swiper-button-prev.how-to-slider',
      },
    });
  }

  // Варианты
  if (document.querySelector('.swiper-container.variants')) {
    let howToSlider = new Swiper('.swiper-container.variants', {
      grabCursor: true,
      autoHeight: true,
      loop: true,
      spaceBetween: 15,
      speed: 500,
      autoplay: {
        delay: 30000,
      },
      autoHeight: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next.variants',
        prevEl: '.swiper-button-prev.variants',
      },
    });
  }

  // Как мы работаем
  if (document.querySelector('.swiper-container.what')) {
    let whatSlider = new Swiper('.swiper-container.what', {
      grabCursor: true,
      loop: true,
      spaceBetween: 24,
      speed: 500,
      autoplay: {
        delay: 30000,
      },
      lazy: {
        loadPrevNext: true,
      },
      autoHeight: true,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        500: {
          slidesPerView: 2,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next.what',
        prevEl: '.swiper-button-prev.what',
      },
    });
  }

  // Отзывы
  if (document.querySelector('.swiper-container.testimonials-slider')) {
    new Swiper('.swiper-container.testimonials-slider', {
      grabCursor: true,
      loop: true,
      spaceBetween: 16,
      speed: 500,
      autoplay: {
        delay: 30000,
      },
      breakpoints: {
        415: {
          slidesPerView: 1,
        },
        1000: {
          slidesPerView: 2,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next.testimonials-slider',
        prevEl: '.swiper-button-prev.testimonials-slider',
      },
      pagination: {
        el: '.testimonials-slider-fraction',
        type: 'custom',
        renderCustom: function (swiper, current, total) {
          return `<span>${padWithLeadingZeros(current, 2)}</span> / ${padWithLeadingZeros(
            total,
            2
          )}`;
        },
      },
      preloadImages: false,
      lazy: true,
      lazy: {
        loadPrevNext: false,
      },
    });
  }

  if (document.querySelector('.showroom-slider')) {
    document.querySelectorAll('.showroom-slider').forEach((el) => {
      const sliderIdentifier = el.dataset.uniqueSlider;
      const sliderName = `.${el.className.replace(/ /g, '.')}`;

      new Swiper(`${sliderName} .swiper-container.showroom-slider-${sliderIdentifier}`, {
        grabCursor: true,
        spaceBetween: 16,
        speed: 500,
        preloadImages: false,
        lazy: {
          loadPrevNext: false,
        },
        slidesPerColumnFill: 'row',
        autoplay: {
          delay: 30000,
        },
        breakpoints: {
          415: {
            slidesPerView: 'auto',
          },
          550: {
            slidesPerView: 2,
          },
          820: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
        },
        navigation: {
          nextEl: `${sliderName} .swiper-button-next.showroom-slider-${sliderIdentifier}`,
          prevEl: `${sliderName} .swiper-button-prev.showroom-slider-${sliderIdentifier}`,
        },
        pagination: {
          el: `.showroom-slider-fraction-${sliderIdentifier}`,
          type: 'custom',
          renderCustom: function (swiper, current, total) {
            return `<span>${padWithLeadingZeros(current, 2)}</span> / ${padWithLeadingZeros(
              total,
              2
            )}`;
          },
        },
      });
    });
  }

  // Попап слайдеры
  if (document.querySelector('.catalog-popup-slider')) {
    $('.catalog-popup-slider').each(function (index, element) {
      let popupSlider = new Swiper('.swiper-container.catalog-popup-slider-' + index, {
        grabCursor: true,
        lazy: true,
        lazy: {
          loadPrevNext: false,
          loadOnTransitionStart: true,
        },
        loop: true,
        speed: 500,

        autoplay: {
          delay: 300000,
        },

        navigation: {
          nextEl: '.swiper-button-next.catalog-popup-slider-' + index,
          prevEl: '.swiper-button-prev.catalog-popup-slider-' + index,
        },

        pagination: {
          el: '.swiper-pagination.catalog-popup-slider-' + index,
          clickable: true,
        },
      });
    });
  }

  // Табы
  let addTabsFunctionality = (tabs, tabsContent) => {
    $(tabs).on('click', function () {
      var target = $(this).attr('data-tabs-content'),
        section = $(this).attr('data-tabs');

      $(this).closest('ul').find('li').removeClass('active');
      $(this).addClass('active');
      $(tabsContent + section).removeClass('active');
      $(tabsContent + target + '.' + section).addClass('active');
    });
  };

  if (document.querySelector('.tabs-header ul li')) {
    addTabsFunctionality('.tabs-header ul li', '.tabs-content .');
  }
  if (document.querySelector('.price__tabs-header li')) {
    addTabsFunctionality('.price__tabs-header li', '.price__tabs-el.');
  }

  // Калькулятор
  if (document.querySelector('.calc__block')) {
    let calcSquareText = $('#range_value_block'),
      calcSquareInp = $('#calc-square'),
      min = 1,
      max = 300;

    $('#calc-square').ionRangeSlider({
      skin: 'round',
      hide_min_max: true,
      min: min,
      max: max,
      step: 1,
      onStart: function (data) {
        calcSquareText.val(data.from);
      },
      onChange: function (data) {
        calcSquareText.val(data.from);
        calcSquareInp.val(data.from);
        calcFunction();
      },
    });

    let instance = $('#calc-square').data('ionRangeSlider');
    calcSquareText.on('focus', function () {
      let val = $(this).val('');
      instance.update({
        from: val,
      });
      calcFunction();
    });
    calcSquareText.on('change keyup', function () {
      let val = $(this).val();
      if (val.match(/\D/)) {
        $(this).val('');
      } else if (val < min) {
        val = min;
        $(this).val('');
      } else if (val > max) {
        val = max;
        $(this).val(max);
      }
      instance.update({
        from: val,
      });
      calcFunction();
    });

    $('span.minus')
      .add('span.plus')
      .on('click', function () {
        let input = $(this).closest('.custom-input__control').find('input'),
          inputVal = Number(input.val());

        if ($(this).hasClass('plus') && inputVal < 99) {
          input.val(inputVal + 1);
        } else if ($(this).hasClass('minus') && inputVal > 0) {
          input.val(inputVal - 1);
        }

        calcFunction();
      });
    $('.custom-select__list').on('click', function () {
      calcFunction();
    });
    $('.custom-input__control input').on('keyup', function () {
      let fieldValue = Number($(this).val());

      if (fieldValue < 0) {
        $(this).val(0);
      } else if (fieldValue > 99) {
        $(this).val(99);
      }

      calcFunction();
    });

    // Функция для расчета
    let calcFunction = function () {
      // Получение значений из ввода
      let matherial = $('ul.custom-select__list input:checked'),
        lustre = Number($('.custom-input.lustre input').val()),
        spot = Number($('.custom-input.spot input').val()),
        square = Number($('#range_value_block').val()),
        lightCost = 450, // Константа для стоимости освещения
        spotCost = 160, // Константа для стоимости спота
        matherialCost;

      // Назначение стоимости материала на основе его типа
      if (matherial.val() == 'Folien EcoPremium (ПВХ)') {
        matherialCost = 545;
      } else if (matherial.val() == 'MSD Premium (ПВХ)') {
        matherialCost = 635;
      } else if (matherial.val() == 'Descor Германия (Ткань)') {
        matherialCost = 1783;
      }

      // Расчет общих и ежемесячных платежей
      let total = square * matherialCost + lustre * lightCost + spot * spotCost,
        totalMonth = total * 0.1;

      // Вывод результатов
      $('#calc_total p span').text(total.toLocaleString('ru'));
      $('#calc_total_month p span.num').text(0);

      if (total > 7000) {
        $('#calc_total_month p span.num').text(totalMonth.toLocaleString('ru'));
      } else {
        $('#calc_total_month p span.num').text(0);
      }
    };
  }

  // кнопка звонка
  if (document.querySelector('.callback-button')) {
    $(window).on('scroll', function () {
      if (window.innerWidth <= 768 && window.scrollY > 20)
        $('.callback-button').removeClass('callback-button--js-is-hidden');
    });
  }

  // aккордеон ЧаВо
  if (document.querySelector('.faq__item')) {
    $('.faq__item').on('click', function (e) {
      if ($(e.target).hasClass('faq__item__question') || $(e.target).hasClass('faq__item__title')) {
        $(this).find('.faq__item__question').toggleClass('open');
        $(this).find('.faq__item__answer').slideToggle();
      }
    });
  }

  // vacancy accodion
  if (document.querySelector('.vacancy-item')) {
    $('.vacancy-item').on('click', function (e) {
      if (
        $(e.target).hasClass('vacancy-item__head') ||
        $(e.target).hasClass('vacancy-item__title')
      ) {
        $(this).find('.vacancy-item__head').toggleClass('open');
        $(this).find('.vacancy-item__about').slideToggle();
      }
    });
  }

  /* ------------ */

  if (document.querySelector('.review-form')) {
    const ratingLabels = document.querySelectorAll('form.review-form .review-form__stars-label');
    const ratingInputs = document.querySelectorAll('form.review-form .review-form__stars-input');

    function notationLabels(e) {
      const label = e.currentTarget;
      let currentLabelActive = label;
      let currentLabelIncative = label;

      if (e.type == 'mouseenter' || !label.control.checked) {
        while (currentLabelActive != null) {
          currentLabelActive.classList.add('js-active');
          currentLabelActive = currentLabelActive.previousElementSibling;
        }

        while ((currentLabelIncative = currentLabelIncative.nextElementSibling) != null) {
          currentLabelIncative.classList.remove('js-active');
        }
      }
    }

    function notationLabelsOut(e) {
      let labels = e.target.parentNode.querySelectorAll('label');
      let currentLabel = labels[labels.length - 1];

      labels.forEach((starLabel) => {
        starLabel.classList.add('js-active');
      });

      while (currentLabel != null && !currentLabel.control.checked) {
        currentLabel.classList.remove('js-active');
        currentLabel = currentLabel.previousElementSibling;
      }
    }

    ratingLabels.forEach((star) => {
      star.addEventListener('click', notationLabels);
      star.addEventListener('mouseenter', notationLabels);
      star.addEventListener('mouseleave', notationLabelsOut);
    });

    ratingInputs.forEach((starInput) => {
      starInput.addEventListener('click', (e) => {
        e.stopPropagation();
      });
    });
  }

  // Попап с локацией
  if (document.querySelector('button.close-button')) {
    $('button.close-button').on('click', (e) => {
      e.currentTarget.closest('#location').classList.toggle('open');

      if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        document.cookie = `user_location=1; path=/; domain=${document.location.hostname}`;
      } else {
        document.cookie = 'user_location=1; path=/; domain=.superpotolok.ru';
      }

      // Разблокировка скролла
      let html = jQuery('html');
      let scrollPosition = html.data('scroll-position');
      html.css('overflow', html.data('previous-overflow'));
      window.scrollTo(scrollPosition[0], scrollPosition[1]);
    });
    $('button.choose-locations').on('click', (e) => {
      e.currentTarget.closest('.modal__block-content').querySelector('.locations').style.display =
        'block';
    });
  }

  // Скрытие блока с локацией при скроле
  if (document.querySelector('#header .location')) {
    window.addEventListener('scroll', () => {
      if (scrollY > 50) {
        document.querySelector('#header .location').classList.add('hidden');
      } else {
        document.querySelector('#header .location').classList.remove('hidden');
      }
    });
  }

  // Добавление border к colors__list__item, имеющему не установленный background-color
  if (document.querySelector('.colors__list')) {
    const colorsList = document.querySelector('.colors__list');
    for (const item of colorsList.querySelectorAll('.colors__list__item')) {
      if (item.querySelector('.colors__list__image').style.backgroundColor == '') {
        item.querySelector('.colors__list__image').classList.add('border-for-uncolor-el');
      }
    }
  }

  // Открытие и закрытие попап слайдера с видео
  if (document.querySelector('.swiper.video-examples-of-work__swiper')) {
    const videoSwiper = document.querySelector('.swiper.video-examples-of-work__swiper');

    videoSwiper.addEventListener('click', (e) => {
      if (!e.target.closest('.swiper-slide.video-examples-of-work__swiper-slide')) {
        return;
      }

      const slideIndex = parseInt(
        e.target.closest('.swiper-slide.video-examples-of-work__swiper-slide').dataset
          .swiperSlideIndex
      );

      // Видео примеры работ (попап)
      let videoExamplesOfWorkPopupSlider = new Swiper(
        '.swiper-container.video-examples-of-work__popup-swiper-container',
        {
          grabCursor: true,
          autoHeight: true,
          loop: true,
          speed: 0,
          initialSlide: slideIndex,
          lazy: true,
          preloadImages: false,
          spaceBetween: 1,
          slidesPerView: 1,
          pagination: {
            el: '.swiper-pagination.video-examples-of-work__popup-pagination',
            clickable: true,
          },
          navigation: {
            prevEl: '.swiper-button-prev.video-examples-of-work__popup-slider-btn',
            nextEl: '.swiper-button-next.video-examples-of-work__popup-slider-btn',
          },
          on: {
            slideChange: function (swiper) {
              for (const video of swiper.slides) {
                video.pause();
                video.currentTime = 0;
              }
            },
          },
        }
      );

      if (document.querySelector('.video-examples-of-work__popup-wrapper')) {
        document.querySelector('.video-examples-of-work__popup-wrapper').classList.add('visible');
      }
    });

    if (document.getElementById('crossExit')) {
      const crossExitBtn = document.getElementById('crossExit');
      crossExitBtn.addEventListener('click', (e) => {
        const popupWrapper = e.target.closest('.video-examples-of-work__popup-wrapper');

        popupWrapper.classList.remove('visible');
        popupWrapper.querySelector('video.swiper-slide.swiper-slide-active').pause();
        popupWrapper.querySelector('video.swiper-slide.swiper-slide-active').currentTime = 0;
      });
    }
  }

  if (document.getElementById('saratovBtn')) {
    document.getElementById('saratovBtn').onclick = function () {
      document.getElementById('saratovBtn').classList.add('active');
      document.getElementById('volgogradBtn').classList.remove('active');
      document.getElementById('krasnodarBtn').classList.remove('active');
      document.getElementById('saratovMap').classList.remove('visually-hidden');
      document.getElementById('volgogradMap').classList.add('visually-hidden');
      document.getElementById('krasnodarMap').classList.add('visually-hidden');
      document.getElementById('saratovContent').classList.remove('visually-hidden');
      document.getElementById('volgogradContent').classList.add('visually-hidden');
      document.getElementById('krasnodarContent').classList.add('visually-hidden');
    };
  }

  if (document.getElementById('volgogradBtn')) {
    document.getElementById('volgogradBtn').onclick = function () {
      document.getElementById('volgogradBtn').classList.add('active');
      document.getElementById('saratovBtn').classList.remove('active');
      document.getElementById('krasnodarBtn').classList.remove('active');
      document.getElementById('volgogradMap').classList.remove('visually-hidden');
      document.getElementById('saratovMap').classList.add('visually-hidden');
      document.getElementById('krasnodarMap').classList.add('visually-hidden');
      document.getElementById('volgogradContent').classList.remove('visually-hidden');
      document.getElementById('saratovContent').classList.add('visually-hidden');
      document.getElementById('krasnodarContent').classList.add('visually-hidden');
    };
  }

  if (document.getElementById('krasnodarBtn')) {
    document.getElementById('krasnodarBtn').onclick = function () {
      document.getElementById('krasnodarBtn').classList.add('active');
      document.getElementById('volgogradBtn').classList.remove('active');
      document.getElementById('saratovBtn').classList.remove('active');
      document.getElementById('krasnodarMap').classList.remove('visually-hidden');
      document.getElementById('volgogradMap').classList.add('visually-hidden');
      document.getElementById('saratovMap').classList.add('visually-hidden');
      document.getElementById('krasnodarContent').classList.remove('visually-hidden');
      document.getElementById('volgogradContent').classList.add('visually-hidden');
      document.getElementById('saratovContent').classList.add('visually-hidden');
    };
  }

  if (document.getElementById('open-callback-soon-modal-btn')) {
    document.getElementById('open-callback-soon-modal-btn').addEventListener('click', (e) => {
      // document.getElementById('callback-menu').classList.remove('open');
      let html = jQuery('html');
      html.css('overflow', 'visible');
      document.getElementById('callback-soon').classList.add('open');
      html.css('overflow', 'hidden');
    });
  }

  if (document.getElementById('open-callback-soon-modal-btn-upper')) {
    document.getElementById('open-callback-soon-modal-btn-upper').addEventListener('click', (e) => {
      let html3 = jQuery('html');
      html3.css('overflow', 'visible');

      // document.getElementById('callback-soon').classList.add('open');
      document.getElementById('callback-menu').classList.add('open');
      // html3.css('overflow', 'hidden');
    });
  }

  if (document.querySelector('.office-geography__tabs-content-real')) {
    document.querySelectorAll('.office-geography__tabs-content-real').forEach((el) => {
      const centerCoordinates = [el.dataset.coordinatesLatitude, el.dataset.coordinatesLongitude];

      ymaps
        .load(
          'https://api-maps.yandex.ru/2.1/?apikey=6b40fb41-3ef3-42a2-8962-da36a5d7ade2&lang=ru_RU'
        )
        .then((maps) => {
          const myMap = new maps.Map(el.id, {
            center: centerCoordinates,
            zoom: 17,
            controls: ['fullscreenControl', 'zoomControl'],
          });

          const HintLayout = maps.templateLayoutFactory.createClass(
            '<div class="yandex-maps__hint">' + '{{ properties.address }}' + '</div>'
          );

          const BalloonContentLayout = maps.templateLayoutFactory.createClass(
            '<div class="yandex-maps__baloon">' + '{{ properties.address }}' + '</div>'
          );

          const myPlacemark = new maps.Placemark(
            centerCoordinates,
            {
              address: el.dataset.address,
            },
            {
              iconLayout: 'default#image',
              iconImageHref: officeGeographyMapData.icon,
              iconImageSize: [68, 93],
              hintLayout: HintLayout,
              balloonContentLayout: BalloonContentLayout,
              hideIconOnBalloonOpen: false,
              balloonShadow: true,
              balloonOffset: [18, -25],
            }
          );

          myMap.geoObjects.add(myPlacemark);

          myMap.behaviors.disable('scrollZoom');

          /*
          Заморозить карту на:
          - смартфоны, устройства с сенсорным экраном, с которым работают пальцами
          - устройства с сенсорным экраном, с которым работают стилусом.
          https://habr.com/ru/companies/ruvds/articles/556156/
          */
          if (window.matchMedia('(pointer:coarse)').matches) {
            myMap.behaviors.disable('drag');
            myMap.behaviors.enable('multiTouch');
          }
        })
        .catch((error) => console.log('Failed to load Yandex Maps', error));
    });
  }

  if (document.querySelector('.office-geography'))
    new HandyCollapse({
      nameSpace: 'office-geography-tab',
      closeOthers: true,
      activeClass: 'js-is-active',
      isAnimation: false,
    });

  if (document.querySelector('.potolok-photos__btn')) {
    const showMoreBtn = document.querySelector('.potolok-photos__btn');
    showMoreBtn.addEventListener('click', (e) => {
      const visiblePhotos = document.querySelectorAll('.potolok-photos__gallery__photo-wrapper');
      const fancyboxVisiblePhoto = document.querySelector('.potolok-photos__gallery__photo-wrapper')
        .firstElementChild.dataset.fancybox;

      const hiddenPhotos = Object.values(visiblePhotos).filter(
        (item, index) => item.style.display === 'none'
      );

      const amountToShow = 12;
      const maxAmount = hiddenPhotos.length < amountToShow ? hiddenPhotos.length : amountToShow;

      for (let i = 0; i < maxAmount; i++) {
        hiddenPhotos[i].style.display = '';
        hiddenPhotos[i].firstElementChild.dataset.fancybox = fancyboxVisiblePhoto;
      }

      if (maxAmount === hiddenPhotos.length) e.currentTarget.classList.add('hidden');
    });
  }

  const heroSwiper = document.querySelector('.hero__swiper');

  if (heroSwiper) {
    const swiperHero = new Swiper(heroSwiper, {
      navigation: {
        nextEl: '.hero__swiper__btn.swiper-button-next',
        prevEl: '.hero__swiper__btn.swiper-button-prev',
      },
      loop: true, // enable infinite loop
      autoplay: {
        delay: 5000,
      },
      effect: 'fade', // set fade effect
      fadeEffect: {
        crossFade: true, // enable cross-fade transition
      },
    });
  }

  // Phone mask / validate
  if (document.querySelectorAll('.phone.mask')) {
    document.querySelectorAll('.phone.mask').forEach((el) => {
      let im = new Inputmask({
        mask: '+374 (99) 999-999',
        definitions: {
          9: {
            validator: '[0-9]',
          },
        },
      });
      im.mask(el);
    });
  }

  // плавный скролл к якорю, навигация в светильниках со смещением
  document.querySelectorAll('.lamps__nav__link').forEach((anchor) => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      var anchorTarget = document.querySelector(this.getAttribute('href'));
      var scrollTarget = anchorTarget.offsetTop - -170; // calculate the target position 60px above the actual target
      window.scrollTo({
        top: scrollTarget,
        behavior: 'smooth',
      });
    });
  });
});

// disabled btn policy
window.addEventListener('DOMContentLoaded', () => {
  const checkboxes = document.querySelectorAll('.checkbox_policy');

  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener('change', function () {
      const submitButton = this.closest('form').querySelector('.send__button');
      this.checked
        ? submitButton.classList.remove('disabled')
        : submitButton.classList.add('disabled');
    });
  });
});
